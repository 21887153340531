import React from "react";
import { Table } from "antd";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";
import formatNumber from "../../utils/format";
import stringToFloat from "../../utils/parser";

const MonthlyTransactionTable = (
  {
    paricipment,
    loadData,
    data,
    pagination = true,
    rowExpandable,
    limit: initialLimit = 100,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes,
    subheaderIndexes,
    initialLoading,
    onTableChange = () => {},
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(perPage);
  const [pageCount, setPageCount] = React.useState(1);
  const [totalEl, setTotalEl] = React.useState(1);

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const [tableheaderIdx, setTableheaderIdx] = React.useState(headerIndexes);
  const [tablesubheaderIdx, setTablesubheaderIdx] =
    React.useState(setSubheaderIndexes);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );
      if (res.type === "error") return console.log("error", res.payload);

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  React.useEffect(() => {
    let headerIdx = [];
    let subheaderIdx = [];
    const listData = [];
    // console.log("first", data);
    if (data != null) {
      if (data && Array.isArray(data)) {
        let totalDebitNumber = 0;
        let totalCreditNumber = 0;
        let totalDebitAmount = 0;
        let totalCreditAmount = 0;

        data.forEach((el) => {
          totalDebitNumber += stringToFloat(el.debitCount.toString());
          totalCreditNumber += stringToFloat(el.creditCount.toString());
          totalDebitAmount += stringToFloat(el.debitAmount.toString());
          totalCreditAmount += stringToFloat(el.creditAmount.toString());

          listData.push(el);
        });

        listData.push({
          date        : "Нийт",
          debitCount  : totalDebitNumber,
          creditCount : totalCreditNumber,
          debitAmount : formatNumber(totalDebitAmount),
          creditAmount: formatNumber(totalCreditAmount),
        });
      }

      setTableheaderIdx(headerIdx);
      setTablesubheaderIdx(subheaderIdx);
      setItems(listData);
    } else {
      setTableheaderIdx([]);
      setTablesubheaderIdx([]);
      setItems([]);
      setLoading(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData(el);
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData(el);
    }
  }, [limit]);

  const useHeader = ({ type, onAction, s3host }) => {
    return [
      {
        title : "Огноо",
        render: (record) => {
          if (record.date) {
            if (moment(record.date, moment.ISO_8601, true).isValid()) {
              return (
                <div style={{ padding: "8px" }}>
                  {moment(record.date).format("YYYY-MM-DD")}
                </div>
              );
            }
            return <div style={{ padding: "8px" }}>{record.date}</div>;
          }
          return <div style={{ padding: "8px" }}>-</div>;
        },
        onCell: (_, index) => {
          return {
            className: index === items.length - 1 ? "header-cell" : "",
          };
        },
      },
      {
        title : "Дебит гүйлгээний тоо",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.debitCount}</div>;
        },
        onCell: (_, index) => {
          return {
            className: index === items.length - 1 ? "header-cell" : "",
          };
        },
      },
      {
        title : "Кредит гүйлгээний тоо",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.creditCount}</div>;
        },
        onCell: (_, index) => {
          return {
            className: index === items.length - 1 ? "header-cell" : "",
          };
        },
      },
      {
        title : "Дебит гүйлгээний дүн",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.debitAmount}</div>;
        },
        onCell: (_, index) => {
          return {
            className: index === items.length - 1 ? "header-cell" : "",
          };
        },
      },
      {
        title : "Кредит гүйлгээний дүн",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.creditAmount}</div>;
        },
        onCell: (_, index) => {
          return {
            className: index === items.length - 1 ? "header-cell" : "",
          };
        },
      },
    ];
  };

  return (
    <>
      <Table
        {...{
          // scroll    : { x: 100 },
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record.id || index}
      />
    </>
  );
};

export default React.forwardRef(MonthlyTransactionTable);
