import React from "react";
import { Table } from "antd";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";
import formatNumber from "../../utils/format";

const TransactionTable = (
  {
    paricipment,
    loadData,
    columns,
    data,
    pagination = true,
    rowExpandable,
    limit: initialLimit = 100,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    headerIndexes,
    subheaderIndexes,
    el,
    isSuccess,
    onTableChange = () => {},
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  // const [tableFilter, setTableFilter] = React.useState({});

  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(perPage);
  const [pageCount, setPageCount] = React.useState(1);
  const [totalEl, setTotalEl] = React.useState(1);
  const [items, setItems] = React.useState([]);

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const [tableheaderIdx, setTableheaderIdx] = React.useState([]);
  const [tablesubheaderIdx, setTablesubheaderIdx] =
    React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );
      if (res.type === "error") return console.log("error", res.payload);

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
    setPage(newPage) {
      // console.log("newPage", newPage);
      setPage(newPage);
      // console.log("newPage page", page);
    },
    setLimit(val) {
      setLimit(val);
    },
  }));
  // console.log("items+++++++++", items);

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  // let allDates = [];
  // let startDate = new Date(filters.startDate);
  // const finalDate = new Date(filters.endDate);

  // while (startDate <= finalDate) {
  //   allDates.push(new Date(startDate));
  //   startDate.setDate(startDate.getDate() + 1);
  // }

  const useHeader = ({ type, onAction, s3host }) => {
    const sharedOnCell = (_, index) => {
      if (tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)) {
        return {
          colSpan: 0,
        };
      }
      return {
        colSpan: 1,
      };
    };

    const fixNest = (_, index) => {
      // console.log("called");
      if (tableheaderIdx.includes(index)){
        return 6;
      }
      if (tablesubheaderIdx.includes(index)){
        return 5;
      }
      return 1;
      // tableheaderIdx.includes(index) ? 6 : tablesubheaderIdx.includes(index) ? 5 : 1
    };

    const fixNest2 = (_, index) => {
      if (tableheaderIdx.includes(index)){
        return "header-cell";
      }
      if (tablesubheaderIdx.includes(index)){
        return "subheader-cell";
      }
      return "cell-center";
      // tableheaderIdx.includes(index)
      // ? "header-cell"
      // : tablesubheaderIdx.includes(index)
      //   ? "subheader-cell"
      //   : "cell-center"
    };

    const onHeaderCell = (_, index) => {
      let idx = fixNest(_, index);
      let classname = fixNest2(_, index);
      return {
        colSpan  : idx,
        className: classname
      };
    };

    return [
      {
        title : "Харилцагч",
        align : "left",
        render: (record) => {
          console.log("000000", record);
          return (
            <div
              style={{
                padding: "8px",
              }}
            >
              {record.key}
            </div>
          );
        },
        onCell: onHeaderCell,
      },
      {
        title : "Гүйлгээний дугаар",
        render: (record, index) => {
          return <div style={{ padding: "8px" }}>{record.transactionCode}</div>;
        },
        onCell: sharedOnCell,
      },
      {
        title : "Огноо",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>
              {record.date ? moment(record.date).format("YYYY-MM-DD") : "-"}
            </div>
          );
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0
              : 1,
        }),
      },
      {
        title : "Илгээсэн харилцагч",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.bankCode}</div>;
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0
              : 1,
        }),
      },
      {
        title : "Илгээсэн данс дугаар",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.bankAccountNumber}</div>
          );
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0
              : 1,
        }),
      },
      {
        title : "Гүйлгээний дүн",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.transactionAmount}</div>
          );
        },
        onCell: (_, index) => ({
          colSpan  : tableheaderIdx.includes(index) ? 0 : 1,
          className: tablesubheaderIdx.includes(index) ? "subvalue-cell" : "",
        }),
      },
    ];
  };

  React.useEffect(() => {
    let headerIdx = [];
    let subheaderIdx = [];
    const listData = [];
    if (data != null) {
      if (data && data.incoming && Array.isArray(data.incoming.data)) {
        listData.push({
          key: `${paricipment} - Хүлээн авсан ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(0);
        data.incoming.data.forEach((item) => {
          const date = Object.keys(item)[0];
          if (date) {
            item[date].forEach((el, i) => {
              listData.push({
                // key: i + 1,
                key: "",
                ...el,
              });
            });
            let totalAmount = 0;
            data.incoming.totals.forEach((el) => {
              if (el.date === date) {
                totalAmount = el.totalAmount;
                // return;
              }
            });
            listData.push({
              key              : `${moment(date).format("YYYY-MM-DD")} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
              transactionAmount: totalAmount,
            });
            subheaderIdx.push(listData.length - 1);
          }
        });
        listData.push({
          key              : `${paricipment} ${isSuccess ? "Нийт хүлээн авсан дүн" : "Хүлээн авсан амжилтгүй дүн"}`,
          transactionAmount: data.incoming.total,
        });
        subheaderIdx.push(listData.length - 1);
      }

      if (data && data.outgoing && Array.isArray(data.outgoing.data)) {
        listData.push({
          key: `${paricipment} - Илгээсэн ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(listData.length - 1);
        data.outgoing.data.forEach((item) => {
          const date = Object.keys(item)[0];
          if (date) {
            item[date].forEach((el, i) => {
              listData.push({
                // key: i + 1,
                key: "",
                ...el,
              });
            });
            let totalAmount = 0;
            data.outgoing.totals.forEach((el) => {
              if (el.date === date) {
                totalAmount = el.totalAmount;
                // return;
              }
            });
            listData.push({
              key              : `${moment(date).format("YYYY-MM-DD")} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
              transactionAmount: totalAmount,
            });
            subheaderIdx.push(listData.length - 1);
          }
        });
        listData.push({
          key              : `${paricipment} ${isSuccess ? "Нийт илгээсэн дүн" : "Илгээсэн амжилтгүй дүн"}`,
          transactionAmount: data.outgoing.total,
        });
        subheaderIdx.push(listData.length - 1);
      }

      // console.log(listData);

      setTableheaderIdx(headerIdx);
      setTablesubheaderIdx(subheaderIdx);
      setLoading(false);
      setItems(listData);

      console.log("listData", listData);

      if (data.pagination) {
        if (
          data.pagination.paginationIncoming.total_elements >
          data.pagination.paginationOutgoing.total_elements
        ) {
          setTotal(data.pagination.paginationIncoming.total_elements);
        } else {
          setTotal(data.pagination.paginationOutgoing.total_elements);
        }

        // if (
        //   data.pagination.paginationIncoming.per_page >
        //   data.pagination.paginationOutgoing.per_page
        // ) {
        //   setLimit(data.pagination.paginationIncoming.per_page);
        // } else {
        //   setLimit(data.pagination.paginationOutgoing.per_page);
        // }

        // if (
        //   data.pagination.paginationIncoming.current_page_no >
        //   data.pagination.paginationOutgoing.current_page_no
        // ) {
        //   setPage(data.pagination.paginationIncoming.current_page_no);
        // } else {
        //   setPage(data.pagination.paginationOutgoing.current_page_no);
        // }

        if (
          data.pagination.paginationIncoming.total_pages >
          data.pagination.paginationOutgoing.total_pages
        ) {
          setPageCount(data.pagination.paginationIncoming.total_pages);
        } else {
          setPageCount(data.pagination.paginationOutgoing.total_pages);
        }

        setTotalEl(
          data.pagination.paginationOutgoing.total_elements +
            data.pagination.paginationIncoming.total_elements
        );
      }
    }
    // console.log("okokoko", listData);
  }, [data]);

  // React.useEffect(() => {
  //   // value.forEach((el) => {
  //   //   getData(el);
  //   // });
  //   getData(el);
  // }, [page]);

  // React.useEffect(() => {
  //   getData(el);
  // }, [limit]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData();
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData();
    }
  }, [limit]);

  return (
    <>
      <Table
        {...{
          // scroll  : { x: 100 },
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record.id || index}
      />
      {pagination &&(
        <Box
          className="custom-pagination"
          style={{
            marginTop     : "0px",
            display       : "flex",
            flexDirection : "row",
            alignItems    : "center",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            component="div"
            count={totalEl}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(1);
            }}
            sx={{
              "& p": {
                marginTop   : "auto",
                marginBottom: "auto",
              },
              ".MuiTablePagination-actions > button:first-of-type": {
                display: "none",
              },
              ".MuiTablePagination-actions > button:last-of-type": {
                display: "none",
              },
              ".MuiTablePagination-displayedRows": {
                display: "none",
              },
            }}
          />
          <Pagination
            count={pageCount}
            page={page}
            onChange={(_, newPage) => {
              setPage(newPage);
            }}
            color="primary"
            shape="rounded"
          />
        </Box>
      )}
    </>
  );
};

export default React.forwardRef(TransactionTable);
