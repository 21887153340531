import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { CustomTable, RowAction } from "../../components";
import { useHistory, Link } from "react-router-dom";
import { feeSettings as feeSettingsApi } from "../../apis";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { tugrug } from "../../utils";

export default () => {
  const myTableRef = React.useRef();
  const { isMobile } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    query : "",
    offset: {
      page : 1,
      limit: 10
    }
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query) {
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const onClear = () => {
    setQuery(null);
    setFilters({
      filter: {
        query: ""
      },
      offset: {
        page : 1,
        limit: 10
      },
      start_date: undefined,
      end_date  : undefined
    });
  };

  const renderFee = (fee) => {
    return (
      <>
        <td className="ant-table-cell">
          {fee.acquirer_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.acquirer_fee_type === "FLAT" ? tugrug(fee.acquirer_fee) : `${fee.acquirer_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.acquirer_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.acquirer_max_amount)}
        </td>
        <td className="ant-table-cell">
          {fee.issuer_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.issuer_fee_type === "FLAT" ? tugrug(fee.issuer_fee) : `${fee.issuer_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.issuer_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.issuer_max_amount)}
        </td>
        <td className="ant-table-cell">
          {fee.bom_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.bom_fee_type === "FLAT" ? tugrug(fee.bom_fee) : `${fee.bom_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.bom_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.bom_max_amount)}
        </td>
        <td className="ant-table-cell">
          {fee.epay_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.epay_fee_type === "FLAT" ? tugrug(fee.epay_fee) : `${fee.epay_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.epay_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.epay_max_amount)}
        </td>
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeader title="Шимтгэл тохиргоо" />
      <PageContent>
        <InoutTable
          size="small"
          ref={myTableRef}
          filters={filters}
          loadData={feeSettingsApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={3}>№</th>
                <th className="ant-table-cell" rowSpan={3}>Харилцагч</th>
                <th className="ant-table-cell" rowSpan={3} colSpan={2}>Гүйлгээний төрөл</th>
                <th className="ant-table-cell" colSpan={16}>ACQUIRER</th>
                <th className="ant-table-cell" colSpan={16}>ISSUER</th>
                <th className="ant-table-cell" colSpan={16}>BOM</th>
                <th className="ant-table-cell" colSpan={16}>E-PAY</th>
                <th className="ant-table-cell" rowSpan={3}>Үйлдэл</th>
              </tr>
              <tr>
                {[1, 2, 3, 4].map((index) => (
                  <>
                    <th key={index + 1} className="ant-table-cell" colSpan={4}>Interval 1</th>
                    <th key={index + 2} className="ant-table-cell" colSpan={4}>Interval 2</th>
                    <th key={index + 3} className="ant-table-cell" colSpan={4}>Interval 3</th>
                    <th key={index + 4} className="ant-table-cell" colSpan={4}>Interval 4</th>
                  </>
                ))}
              </tr>
              <tr>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((index) => (
                  <>
                    <th className="ant-table-cell" width="100" key={index + 1}>Төрөл</th>
                    <th className="ant-table-cell" width="100" key={index + 2}>Шимтгэл</th>
                    <th className="ant-table-cell" width="100" key={index + 3}>Бага дүн</th>
                    <th className="ant-table-cell" width="100" key={index + 4}>Их дүн</th>
                  </>
                ))}
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={6}>{index + 1}</td>
                  <td className="ant-table-cell" rowSpan={6}>
                    <div>{row.operator_name}</div>
                    <Tag>{row.operator_code}</Tag>
                  </td>
                  <td className="ant-table-cell" rowSpan={3}>
                    Inbank
                  </td>
                  {((fee) => {
                    return (
                      <>
                        <td className="ant-table-cell">
                          <b>{fee.transaction_type}</b>
                        </td>
                        {fee.intervals.map((fee => {
                          return renderFee(fee);
                        }))}
                      </>
                    );
                  })(row.settings[0])}
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      actions = {{
                        edit: "Засварлах"
                      }}
                      onClick={() => { history.push(`/fee/settings/${row.operator_id}`); }}
                    />
                  </td>
                </tr>

                {row.settings.slice(1, 6).map((fee, index) => (
                  <tr key={index}>
                    {index === 2 && (
                      <td className="ant-table-cell" rowSpan={3}>
                        Interbank
                      </td>
                    )}
                    <td className="ant-table-cell">
                      <b>{fee.transaction_type}</b>
                    </td>

                    {fee.intervals.map((fee) => {
                      return renderFee(fee);
                    })}

                    {index === 1 && (
                      <td className="ant-table-cell" rowSpan={2}>{row.setting_status === "ACTIVE" ? <Tag color="green">ИДЭВХТЭЙ</Tag> : <Tag color="red">ИДЭВХГҮЙ</Tag>}</td>
                    )}

                    {index === 3 && (
                      <td className="ant-table-cell" rowSpan={2}>{moment(row.setting_status_date).format("YYYY.MM.DD HH:mm")}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            );
          }} />
      </PageContent>
    </PageContainer>
  );
};

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;
