import React, { useRef } from "react";
import { Button, DatePicker, Select, message, Typography } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import monthlyTransactionTable from "../MyTable/monthlyTransactionTable";
import { Filter } from "../Design";
import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";

export default ({ reportType, fileName, reportName }) => {
  const { accessToken, user } = useSelector((state) => state.auth);

  const [data, setData]=React.useState();

  const operatorCode=user?.operator?.code||"";
  const operatorName=user?.operator?.name||"Оператор байхгүй";

  const [value, setValue] = React.useState([]);

  const [loading, setLoading]=React.useState(false);

  const [filters, setFilters] = React.useState({
    startDate: "",
    endDate  : "",
  });
  const { Title } = Typography;

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      endDate  : e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
    });
  };

  React.useEffect(() => {
    if (filters.startDate!==""&&filters.endDate!=="") {
      getData();
    } setData(null);
  }, [filters]);

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setValue([]);
  };

  const getData = () => {
    setLoading(true);
    fetchReportData("/report/monthly/transaction", "POST", accessToken, {
      ...filters,
      transactionStatus: reportType,
      operatorCode
    })
      .then((data) => {
        if (data.responseCode) {
          setData(data.data);
        }
        setLoading(false);
        console.log("first", data);
      })
      .catch((err) => {
        console.log("err ", err);
        // }
      });
  };

  const tableRef = useRef();

  const handlePrint = () => {
    const originalContents = document.body.innerHTML;
    const printContents = tableRef.current.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <PageContainer>
      <Title level={ 2 } style={ { fontSize: "20px", paddingTop: "25px", paddingBottom: "19px" } }>{reportName}</Title>
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              key="RangeDatePicker2"
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button key="ClearButton3" onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "/report/monthly/transaction?xlsx=true",
                  "POST",
                  accessToken,
                  {
                    ...filters,
                    operatorCode,
                    transactionStatus: reportType,
                  },
                  `${fileName}_${operatorName}`.toLowerCase()
                );
              }}
            >
              Татах
            </Button>,
            <Button onClick={data ? handlePrint : data}
            >
              PDF Татах
            </Button>,
          ]}
        ></Filter>

        {data !== null && (
          <React.Fragment>
            <TableContainer ref={tableRef}>
              <Table
                initialLoading={true}
                filters={filters}
                data={data}
                paricipment={operatorName}
                rowKey={(record) => record?.rowKey}
                getData={getData}
                value={value}
              />
            </TableContainer>
            <div style={{ height: "30px" }}></div>
          </React.Fragment>
        )}
      </PageContent>
    </PageContainer>
  );
};

const TableContainer = styled.div`
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  /* Print-specific styles */
  @media print {
    th, td {
      font-size: 12px;
      padding: 8px;
    }
    
    body {
      margin: 0;
      padding: 0;
      width: auto;
    }
    
    /* Hide certain elements during print */
    button {
      display: none;
    }

    .filter-container {
      display: none;
    }
  }
`;

const Table = styled(monthlyTransactionTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
