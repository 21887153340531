import { saveAs } from "file-saver";

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
let host = "http://103.119.92.100:30909";

export const fetchReportData = async (url, method, accessToken, data) => {
  if (!myHeaders.has("Authorization")) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }
  let response = await fetch(`${host}${url}`, {
    method : method,
    headers: myHeaders,
    body   : JSON.stringify(data),
  });

  // console.log("response", response);
  let textResponse = await response.text();
  // console.log("textResponse", textResponse);
  return JSON.parse(textResponse);
};

export const fetchReportFile = async (url, method, accessToken, data, name) => {
  if (!myHeaders.has("Authorization")) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }
  let response = await fetch(`${host}${url}`, {
    method : method,
    headers: myHeaders,
    body   : JSON.stringify(data),
  });
  try {
    const contentType = response.headers.get("content-type");
    // console.log("Content type:", contentType);

    if (
      contentType &&
      (contentType.includes("application/vnd.ms-excel") ||
        contentType.includes("application/octet-stream"))
    ) {
      const blob = await response.blob();
      // console.log("Blob:", blob);
      // console.log("Blob type:", blob.type);
      saveAs(blob, `${name}.xlsx`);
    } else {
      throw new Error(`Unexpected content type: ${contentType}`);
    }
  } catch (error) {
    // console.log("fetchReportFile", error);
  }
};
