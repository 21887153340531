import React from "react";
import ReconcilationReportPage from "../../components/ReportPage/reconcilationReportPage";

export default () => {
  return (
    <ReconcilationReportPage
      fileName={"reconcilation_report"}
      reportName={"Баталгаажуулах тайлан"}
    />
  );
};
