const initialState = {
  access_token : null,
  refresh_token: null,
  user         : null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "auth.me":
      const { ...rest } = action.payload;
      return {
        ...state,
        user: rest,
      };
    case "auth.change":
      const { access_token, refresh_token, operator_name } = action.payload;

      return {
        ...state,
        accessToken : access_token,
        refreshToken: refresh_token,
        operatorName: operator_name,
      };
    case "auth.logout":
      return initialState;
    default:
      return state;
  }
};

export default auth;
