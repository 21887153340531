import React from "react";
import TransactionReportPage from "../../components/ReportPage/transactionReportPage";

export default () => {
  return (
    <TransactionReportPage
      reportType={"SUCCESS"}
      fileName={"successful_transaction_"}
      reportName = {"Амжилттай гүйлгээний тайлан"}
      isSuccess={true}
    />
  );
};
