import React from "react";
import { Tag, Button, DatePicker, Modal, message } from "antd";
import { settlement as settlementApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { CustomTable, RowAction } from "../../components";
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Filter } from "../../components/Design";
import styled from "styled-components";
import { tugrug } from "../../utils";
import Form from "./Form";
import moment from "moment";
import { useSelector } from "react-redux";

const TYPES = {
  card   : "BANK_CARD",
  account: "BANK_ACCOUNT"
};

export default () => {
  const myTableRef = React.useRef();
  const { s3host } = useSelector(state => state.general);
  const [action, setAction ] = React.useState([]);
  const params = useParams();

  const [filters, setFilters] = React.useState({
    type      : TYPES[params.type],
    start_date: "",
    end_date  : ""
  });

  const dateFilter = (e)=> {
    setFilters({ ...filters, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e &&moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") });
  };

  const onAction = async (type, row) => {
    switch (type) {
      case "download": {
        window.open(s3host + row.file_url, "_blank");
        break;
      }
      default:
    }
  };

  const onCancel = (reload) => {
    setAction([]);

    if (reload)
      myTableRef.current.reload();
  };

  const onClear = () => {
    setFilters({
      type      : TYPES[params.type],
      start_date: undefined,
      end_date  : undefined
    });
  };

  React.useEffect(() => {
    if (params.type)
      setFilters(state => ({
        ...state,
        type: TYPES[params.type]
      }));
  }, [params.type]);

  return (
    <PageContainer>
      <PageHeader title="Шимтгэл тооцоолол" extra={[<Button type="primary" onClick={() => setAction(["settlement"])} icon={<PlusOutlined />}>Шимтгэл тооцоолох</Button>]}/>
      <PageContent>
        <Filter extra={[
          <DatePicker.RangePicker value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />,
          <Button onClick={onClear}>Цэвэрлэх</Button>]} >
        </Filter>

        <Table
          ref={myTableRef}
          filters={filters}
          loadData={settlementApi.list}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Харилцагч</th>
                <th className="ant-table-cell" colSpan={2}>Хугацаа</th>
                <th className="ant-table-cell" colSpan={2}>Гүйлгээ</th>
                <th className="ant-table-cell" colSpan={4}>Шимтгэл</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell">Эхлэх огноо</th>
                <th className="ant-table-cell">Дуусах огноо</th>
                <th className="ant-table-cell">Тоо</th>
                <th className="ant-table-cell">Нийт дүн</th>
                <th className="ant-table-cell">ACQUIRER</th>
                <th className="ant-table-cell">ISSUER</th>
                <th className="ant-table-cell">BOM</th>
                <th className="ant-table-cell">EPAY</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <>
                <tbody key={index} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell" rowSpan={2}>{index + 1}</td>
                    <td className="ant-table-cell">
                      {row.operator_name}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {moment(row.start_date).format("YYYY-MM-DD")}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {moment(row.end_date).format("YYYY-MM-DD")}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {row.total_count}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {tugrug(row.total_amount)}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {tugrug(row.acquirer_total_amount)}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {tugrug(row.issuer_total_amount)}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {tugrug(row.bom_total_amount)}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {tugrug(row.epay_total_amount)}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      <Tag>{row.settlement_status}</Tag>
                    </td>
                    <td className="ant-table-cell" rowSpan={2} style={{ minWidth: 180 }}>{moment(row.settlement_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td className="ant-table-cell" rowSpan={2}>
                      <RowAction
                        actions = {{
                          download: "Татах"
                        }}
                        onClick={(key) => onAction(key, row)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="ant-table-cell">
                      <Tag>{row.operator_code}</Tag>
                    </td>
                  </tr>
                </tbody>
                <tbody key={index} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell" colSpan={13}>
                      <h4>Тооцооллын мэдээлэл</h4>
                      <table>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell fee">Нэмэлт тайлбар</th>
                            <th className="ant-table-cell fee">Тооцоолсон ажилтан</th>
                            <th className="ant-table-cell fee">Тооцоолсон огноо</th>
                          </tr>
                        </thead>
                        <tbody key={index}>
                          <tr>
                            <td className="ant-table-cell fee">{row.note || "-"}</td>
                            <td className="ant-table-cell fee">{row.staff_name || "-"}</td>
                            <td className="ant-table-cell fee">{moment(row.refund_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </>
            );
          }} />
      </PageContent>

      <Modal
        title="Тооцоолол хийх"
        open={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} action={action} />
      </Modal>
    </PageContainer>
  );
};

const Table = styled(CustomTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
    &.fee {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;