import React from "react";
import { Table } from "antd";

const MyTable = ({ loadData, columns, pagination = true, rowExpandable, limit: initialLimit = 100, filters, items: rows, onTableChange = () => {}, onResult = () => {}, ...rest }, ref) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  // const [tableFilter, setTableFilter] = React.useState({});

  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(initialLimit);

  const onChange = (pagination, filterData, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    onTableChange(filterData);

    if (sorter && Object.keys(sorter).length && sorter.order) {
      setField(sorter.columnKey);
      setSort(sorter.order === "ascend" ? 1 : -1);
    } else {
      setField(field);
      setSort(sort);
    }
  };

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData({
        filter: {
          query: "",
          ...filters
        },
        order: {
          [field]: sort
        },
        offset: {
          page : page,
          limit: limit
        }
      }, { signal });
      if (res.type === "error") return console.log("error", res.payload);

      setItems(res.rows.map((row, index) => ({
        ...row,
        rowKey: (index + 1)
      })));
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    }
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  return (
    <Table
      {...{
        // scroll    : { x: 100 },
        bordered  : true,
        // size        : "small",
        columns,
        dataSource: items,
        rowExpandable,
        pagination: pagination
          ? {
            defaultCurrent : 1,
            showTitle      : true,
            showSizeChanger: true,
            showQuickJumper: true,
            // showTotal      : (total, range) => (
            //   <span>
            //     Нийт <strong>{total}</strong>-c <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
            //     -г харуулж байна
            //   </span>
            // ),
            total          : total,
            pageSize       : limit,
            current        : page
          }
          : false,
        onChange,
        ...rest
      }}
      loading={loading}
      rowKey={(record, index) => record.id || index}
    />
  );
};

export default React.forwardRef(MyTable);
