import React from "react";
import MonthlyBill from "../../components/ReportPage/monthlyBillingInvoiceReportPage";

export default () => {
  return (
    <MonthlyBill
      reportType={"SUCCESS"}
      fileName={"monthly_bill"}
      reportName={"Харилцагчийн шимтгэл тооцоо нэхэмжлэх тайлан"}
      isSuccess={true}
    />
  );
};
