import React from "react";
import NetSettlementPage from "../../components/ReportPage/netSettlementPage";

export default () => {
  return (
    <NetSettlementPage
      fileName={"net_settlement"}
      reportName={"Хаалтын тайлан"}
    />
  );
};
