import React, { useRef } from "react";
import { Button, DatePicker, Select, message, Typography } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import { Filter } from "../Design";
import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import monthlyBillingTable from "../MyTable/monthlyBillingTable";

export default ({ reportType, fileName, reportName, isSuccess }) => {
  const { accessToken, user }=useSelector((state) => state.auth);

  const operatorCode=user?.operator?.code||"";
  const operatorName=user?.operator?.name||"Оператор байхгүй";
  let initPage = 1;
  let initLimit = 5;

  const [data, setData]=React.useState();
  const [loading, setLoading]=React.useState(false);
  const [disablePagination, setDisablePagination] = React.useState(false);

  const [filters, setFilters]=React.useState({
    startDate: "",
    endDate  : "",
  });

  const { Title } = Typography;

  const dateFilter=(e) => {
    setFilters({
      ...filters,
      startDate: e&&moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      endDate  : e&&moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
    });
  };

  const onClear=() => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setData(null);
  };

  const getData=(paramPage, paramLimit) => {
    // let page = initPage;
    // if (paramPage === undefined) {
    //  if (tableRef.current != null) {
    //    page = tableRef.current.getPage();
    //  }
    // }
    // let limit = disablePagination ? 9999 : initLimit;
    // if (paramLimit === undefined) {
    //  if (tableRef.current != null) {
    //    limit = tableRef.current.getLimit();
    //  }
    // }
    setLoading(true);
    fetchReportData("/report/monthly/invoice", "POST", accessToken, {
      request: {
        // pageNo : page,
        // perPage: limit,
        sort: "",
      },
      ...filters,
      operatorCode,
    })
      .then((data) => {
        if (data.responseCode) {
          setData(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err ", err);
        setLoading(false);
      });
  };

  const tableRef = useRef();

  const handlePrint = () => {
    // Disable pagination before printing
    setDisablePagination(true);
    // Use a timeout to allow the table to re-render without pagination
    setTimeout(() => {
      const originalContents = document.body.innerHTML;
      const printContents = tableRef.current.innerHTML;
      document.body.innerHTML = printContents; // Display only the table for printing
      window.print(); // Trigger the print dialog
      document.body.innerHTML = originalContents; // Restore the original content
      window.location.reload(); // Reload the page to restore the table with pagination
      // Re-enable pagination after printing
      setDisablePagination(false);
    }, 500); // A short delay to ensure the table re-renders fully without pagination
  };

  React.useEffect(() => {
    if (filters.startDate!==""&&filters.endDate!=="") {
      getData();
    } setData(null);
  }, [filters]);

  return (
    <PageContainer>
      <Title level={ 2 } style={ { fontSize: "20px", paddingTop: "25px", paddingBottom: "19px" } }>{reportName}</Title>
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              key="RangeDatePicker1"
              value={[
                filters.startDate&&moment(filters.startDate),
                filters.endDate&&moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button key="ClearButton2" onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "/report/monthly/invoice?xlsx=true",
                  "POST",
                  accessToken,
                  {
                    ...filters,
                    operatorCode,
                    transactionStatus: reportType,
                  },
                  `${fileName}_${operatorName}`.toLowerCase()
                );
              }}
            >
              Татах
            </Button>,
            <Button onClick={data ? handlePrint : data}
            >
              PDF Татах
            </Button>,
          ]}
        ></Filter>

        {data !== null && (
          <React.Fragment>
            <TableContainer ref={tableRef}>
              <Table
                initialLoading={loading}
                filters={filters}
                data={data}
                paricipment={operatorName}
                rowKey={(record) => record?.rowKey}
                getData={getData}
                isSuccess={isSuccess}
                pagination={disablePagination ? false : { pageSize: 10 }}
              />
            </TableContainer>
            <div style={{ height: "30px" }}></div>
          </React.Fragment>
        )}
      </PageContent>
    </PageContainer>
  );
};

const TableContainer = styled.div`
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  /* Print-specific styles */
  @media print {
    th, td {
      font-size: 12px;
      padding: 8px;
    }
    
    body {
      margin: 0;
      padding: 0;
      width: auto;
    }
    
    /* Hide certain elements during print */
    button {
      display: none;
    }

    .filter-container {
      display: none;
    }
  }
`;

const Table=styled(monthlyBillingTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
