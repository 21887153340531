import React from "react";
import TransactionReportPage from "../../components/ReportPage/transactionReportPage";

export default () => {
  return (
    <TransactionReportPage
      reportType={"REFUNDED"}
      fileName={"unsuccessful_transaction_"}
      reportName={"Амжилтгүй гүйлгээний тайлан"}
      isSuccess={false}
    />
  );
};
